import * as React from 'react'
import { useEffect, useState } from "react";
// import { navigate } from "gatsby";
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby";

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";

import {
  getNewsletterFields,
  handleOnChange,
  handleOnSubmit,
  handleNewsletterOnSubmit,
} from '../utils/mod.js';

import "../styles/css/legals.css"

const Legals = ({ doc, lang }) => {

  function cl(link) { return createLink(link, lang); }

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';
  const newsletterFields = getNewsletterFields(doc);

  let indexCatL = 0,
    indexCatR = 0;

  let mount = true;
  useEffect(() => {
    const newsletterContainer = document.querySelector('.section-newsletter .wrapper form .container-form');
    const newsletterBtn = newsletterContainer.querySelector('.container button');

    function tableLegals() {
      let tableContainer = document.querySelectorAll('.section-legals .container .content .container-table');
      if (tableContainer) {
        let table = tableContainer[0].childNodes[0].data;
        tableContainer[0].innerHTML = table;
      }
    }

    tableLegals()

    function linkLegals() {
      let linkContainer = document.querySelectorAll('.section-legals .container .content .container-link');
      if (linkContainer) {
        let link = linkContainer[0].childNodes[0].data;
        linkContainer[0].innerHTML = link;
      }
    }

    linkLegals()

    function dropdownLegal() {
      let dropdown = document.querySelectorAll('.section-legals .wrapper .container .content .container-dropdown .dropdown');
      dropdown.forEach(function (drop) {
        drop.addEventListener('click', () => {
          if (!drop.classList.contains('style-active')) {
            drop.classList.add('style-active');
          }
          else {
            drop.classList.remove('style-active');
          }
        })
      })
    }
    
    dropdownLegal()

    handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);
  }, [mount]);

  return (
    <>
      <section className="section-legals">
        <div className="wrapper">
          <div className="container">
            <ul>
              {doc.body.map((slice, i) => {
                if (slice.slice_type === "title_cat") {
                  indexCatL++
                  return (
                    <li key={i}>
                      <a href={"#s" + indexCatL} >{RichText.asText(slice.primary.text)}</a>
                    </li>
                  )
                } else { return (<React.Fragment key={i} />) }
              })}

            </ul>
            <div className="content">
              {doc.body.map((slice, i) => {
                if (slice.slice_type === "text") {
                  return (
                    <div key={i} className="text" >
                      <RichText render={slice.primary.text} />
                    </div>
                  )
                } else if (slice.slice_type === "title_cat") {
                  indexCatR++
                  return (
                    <div key={i} className="text" id={"s" + indexCatR} >
                      <RichText render={slice.primary.text} />
                    </div>
                  )
                } else if (slice.slice_type === "separate") {
                  return (
                    <div key={i} className="sep"></div>
                  )
                } else if (slice.slice_type === "table") {
                  return (
                    <div key={i} className="container-table">
                      {RichText.asText(slice.primary.table)}
                    </div>
                  )
                } else if (slice.slice_type === "link") {
                  return (
                    <div key={i} className="container-link">
                      {RichText.asText(slice.primary.link)}
                    </div>
                  )
                } else if (slice.slice_type === "dropdown") {
                  return (
                    <div className="container-dropdown" key={i}>
                      {doc.body[i].items.map((element, l) => (
                        <div key={l} className="dropdown">
                          <div className="head">
                            <div className="title">{RichText.asText(element.title)}</div>
                            <div className="cross">
                              <div className="line"></div>
                              <div className="line"></div>
                            </div>

                          </div>
                          <div className="content">
                            <RichText key={i} render={element.text} />
                          </div>
                        </div>
                      ))}

                    </div>
                  )
                } else return (<React.Fragment key={i} />)
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section-newsletter">
        <div className="wrapper">
          <div className="container-title">
            <div className="container-title">
              <h2>{RichText.asText(doc.newsletter_title)}</h2>
              <p>{RichText.asText(doc.newsletter_text)}</p>
            </div>
          </div>
          <form onSubmit={(e) => handleOnSubmit(e, getUrl)}>
            <div className="container-form">
              <div className="container">
                <div className="container-input">
                  {newsletterFields.map((field) => (
                    <div className="input" key={field.id}>
                      <input
                        type={field.id === "nEmail" ? "email" : "text"}
                        id={field.id}
                        onChange={handleOnChange}
                      />
                      <span className="placeholder">
                        {RichText.asText(field.content)} <i>*</i>
                      </span>
                      <span className="error">
                        Champ invalide
                      </span>
                    </div>
                    ))
                  }
                  <div className="input">
                    <input
                    name="cc-num"
                    className="hidden"
                    required=""
                    value="HubspotCollectedFormsWorkaround"
                    id="cc-num"
                    onChange={handleOnChange}
                  />
                  </div>
                </div>
                <button>
                  <span className='btn-text'>{RichText.asText(doc.newsletter_btntxt)}</span>
                  <div className="loading">
                    <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-load.svg#content"></use>
                    </svg>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                    </svg>
                  </div>
                </button>
              </div>
              <p className="rqd">
                <i>*</i> {RichText.asText(doc.newsletter_rqd)}
              </p>
              <div className="container-checkbox">
                <div className="checkbox">
                  <input type="checkbox" id="check1" onChange={handleOnChange}/>
                  <div className="text">
                    <p id="check1Txt">{RichText.asText(doc.newsletter_info1)}</p>
                    <a href={cl(doc.newsletter_lnklnk)} target={doc.newsletter_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.newsletter_lnktxt)}</a>
                  </div>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="check2" />
                  <div className="text">
                    <p>{RichText.asText(doc.newsletter_info2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

const InitLegals = ({ data }) => {
  const lang = data.prismicLegals.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const categories = data.allPrismicCategories.edges;
  const articles = data.allPrismicArticle.edges;
  const footer = JSON.parse(data.prismicFooter.dataString);
  const doc = JSON.parse(data.prismicLegals.dataString);

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head
        doc={doc}
        lang={lang}
      />
      <div className="legals">
        <Layout
          focus={focus}
          setFocus={setFocus}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"Legals"}
        >
          <main>
            <Legals
              doc={doc}
              lang={lang}
            />
          </main>
        </Layout>
      </div>
    </>
  );
};

export default InitLegals;

export const query = graphql`
query callLegals($lang: String) {
  prismicHeader(lang: { eq : $lang }) {
    dataString
  }
  allPrismicCategories(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicArticle(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            dataString
            first_publication_date
        }
    }
  }
  prismicLegals(lang: {eq: $lang}) {
      dataString
      lang
      uid
  }
  prismicFooter(lang: {eq: $lang}) {
    dataString
  }
  allPrismicFocus(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            dataString
            first_publication_date
            uid
        }
    }
  }
}
`